import Plot from 'react-plotly.js';
import { useEffect, useState } from 'react';
import { Button, Card, Modal, Spinner } from 'react-bootstrap';
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Gaps = ({dataset, name, from, to}) => {
    const [data, setData] = useState([]);
    const [layout, setLayout] = useState(null);
    const [config, setConfig] = useState(null);
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        if(name){
            setLoading(true);
            setTimeout(async()=>{
                const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets/${dataset}/drivers/${name}?action=gaps&date_from=${from}&date_to=${to}`, { headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }});
                if(response.status === 200){
                    const result = await response.json();
                    const { x, y, type, mode, connectgaps, name, marker, textfont, error_y, error_x, line, xaxis, yaxis, frame} = result.data;
                    const traces = [];
                    for (let i = 0; i < x.length; i++) {
                        traces.push({
                            x: x[i],
                            y: y[i],
                            mode: mode[i],
                            type: type[i],
                            name: name[i],
                            connectgaps: connectgaps[i],
                            marker: { 
                                color: marker.color[i],
                                line: {
                                    color: marker.line.color[i]
                                },
                            },
                            textfont: { 
                                color: textfont.color[i],
                            },
                            error_y: { color: error_y.color[i] },
                            error_x: { color: error_x.color[i] },
                            line: { color: line.color[i] },
                            xaxis: xaxis[i],
                            yaxis: yaxis[i],
                            frame: frame[i],
                        });
                    }
                    setData(traces);
                    setLayout(result.layout);
                    setConfig(result.config);
                } else {
                    setData([]);
                    setConfig(null);
                    setLayout(null);
                }
                setLoading(false);
            },100);
        }
    },[dataset, name, from, to]);
    
    return (
        <div className='mt-3'>
            <Card>
                <Card.Header className='border-0 bg-transparent fw-bold'>
                    Gaps
                    <Button className='float-end' variant='default' size='sm'onClick={handleShow}><FontAwesomeIcon icon={faWindowMaximize}/></Button>
                </Card.Header>
                <Card.Body>
                {
                    loading && <div className='text-center'><Spinner style={{ width: "100px", height: "100px"}}/></div>
                }
                {
                    !loading && !layout && <p>No data found for this timeframe</p>
                }
                {
                    !loading && data && layout && <Plot config={config} data={data} layout={layout} className='w-100'/>
                }
                </Card.Body>
            </Card>
            <Modal show={show} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Gaps</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                    !loading && data && layout && <Plot config={config} data={data} layout={layout} className='w-100'/>
                }
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Gaps;